<script lang="ts" setup>
import { ref, computed, watch, onMounted, getCurrentInstance } from "vue";
import { getConstructionDetails } from "@/services/api/construction.api";
import { patchConstructionDetails, postConstructionDetails } from "@/services/api/construction.api";
import { getConstructionBoundaryTypes, getConstructionSourceTypes, getConstructionKinds, getConstructionInsulationCategory } from "@/services/api/masterdata.api";
import { ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import useDetailEdit from "@/composables/detailEdit.js";
import _ from "lodash";
import { constructionKind } from "@/services/constructionKind";
import DateInput from "@/components/general/DateInput.vue";
import { navigateToLocation } from "@/composables/general.js";
import ConstructionKindInput from "@/components/location/ConstructionKindInput.vue";

const {
  setMode,
  setReadonlyMode,
  setEditMode,
  setEditTLMode,
  setDetails,
  hasDetails,
  selectNewIndex,
  selectedData,
  updateSelectedDetail,
  resetSelectedDetail,
  isReadOnly,
  isAddingMode,
  isAddingMasterMode,
  isAddingDetailMode,
  isEditDataMode,
  isEditTLMode,
  allowEdit,
  allowEditTL,
  addNewDetailMode,
  detailTabs,
  modDateFormatted,
  checkDateValid,
} = useDetailEdit();

setInteractionMode("eager");

const showKinds = [
  constructionKind.frontage,
  constructionKind.frontageExclAus,
  constructionKind.frontageInclPanels,
  constructionKind.roof,
  constructionKind.panel,
  constructionKind.floor,
  constructionKind.door,
  constructionKind.glass,
  constructionKind.glassPrimary,
  constructionKind.glassSecondary,
];

const props = defineProps({
  id: String,
  locationReference: String,
  addModus: {
    type: Boolean,
    default: false,
  },
});

const sourceTypes = ref([]);
const boundaryTypes = ref([]);
const loadedKinds = ref([]);
const insulationCategories = ref([]);

const dataMustBeRetrieved = ref(false);

const sourceTypesLoaded = ref(false);
const boundaryTypesLoaded = ref(false);
const kindsLoaded = ref(false);
const insulationCategoriesLoaded = ref(false);
const isSaving = ref(false);

const count = ref(0);
const selectedParts = ref([]);
const selectedTabIndex = ref(undefined);

const masterDataLoaded = computed(() => sourceTypesLoaded.value && boundaryTypesLoaded.value && kindsLoaded.value && insulationCategoriesLoaded.value);
const kinds = computed(() => {
  if (kindsLoaded.value) {
    return loadedKinds.value.filter((item) => showKinds.includes(item.key));
  } else {
    return [];
  }
});

watch(
  () => [props.id, props.addModus],
  () => {
    setMode(props.id, props.addModus);
    if (!isAddingMasterMode.value) {
      dataMustBeRetrieved.value = true;
      fetchData();
    }
  },
  { immediate: true }
);

watch(
  () => masterDataLoaded.value,
  () => {
    if (!isAddingMode.value) {
      fetchData();
    }
  }
);

watch(
  () => selectedTabIndex.value,
  () => {
    selectNewIndex(selectedTabIndex.value);
  }
);

watch(
  () => selectedData.value,
  () => {
    count.value += 1;
    selectedParts.value.splice(0);
    if (selectedData.value.parts) {
      showKinds.forEach((item) => {

        const part = selectedData.value.parts.find((x) => {
          return x.kindKey === item;
        });
        if (part !== undefined) {
          selectedParts.value.push({ ...part });
        }
      });
    }
  }
);

onMounted(() => {
  loadSourceTypes();
  loadBoundaryTypes();
  loadKinds();
  loadInsulationCategories();
});

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  if (dataMustBeRetrieved.value && masterDataLoaded.value) {
    const response = await getConstructionDetails(props.id);
    // console.log("======= ConstructionDetails fetchData, response: ", response);
    if (response && !response.error) {
      selectedTabIndex.value = setDetails(response.data);
    } else {
      proxy.$toaster.error("Ophalen van de data is mislukt! " + response.error);
    }
    dataMustBeRetrieved.value = false;
  }
}

function getPartData(kindKey) {
  const part = selectedParts.value.find((x) => {
    return x.kindKey === kindKey;
  });
  return part !== undefined ? part : undefined;
}

function updatePart(data) {
  const partIndex = selectedParts.value.findIndex((x) => {
    return x.kindKey === data.kindKey;
  });
  if (partIndex > -1) {
    if (data.active) {
      selectedParts.value.splice(partIndex, 1, { ...data });
    } else {
      selectedParts.value.splice(partIndex, 1);
    }
  } else {
    if (data.active) {
      selectedParts.value.push({ ...data });
    }
  }
}

const obs = ref(null);

async function saveDetail() {
  isSaving.value = true;
  const valid = await obs.value.validate();
  if (valid) {
    let data = {};
    if (isEditTLMode.value) {
      data = { valFrom: selectedData.value.valFrom, valTill: selectedData.value.valTill, modDescr: selectedData.value.modDescr };
    } else {
      data = JSON.parse(JSON.stringify(selectedData.value));
      data.parts = [...selectedParts.value];
      //datums mogen niet geupdate worden
      data.valFrom = null;
      data.valTill = null;
    }
    const result = await patchConstructionDetails(selectedData.value.detailId, data);
    if (!result.error) {
      selectedData.value.parts = [...selectedParts.value];
      updateSelectedDetail();
      if (isEditTLMode.value) {
        // refresh de data i.v.m. gewijzigde tijdlijn.
        fetchData();
      }
      setReadonlyMode();
      proxy.$toaster.success("Gegevens van gebouw zijn successvol gewijzigd.");
    } else {
      proxy.$toaster.error(result.error);
    }
  }
  isSaving.value = false;
}

function addNewDetail() {
  if (selectedData.value) {
    addNewDetailMode();
  } else {
    proxy.$toaster.warning("Selecteer eerst een versie s.v.p..");
  }
}

async function saveNewDetail() {
  isSaving.value = true;
  const valid = await obs.value.validate();
  if (valid) {
    let data = {};
    data = JSON.parse(JSON.stringify(selectedData.value));
    data.sourceType = selectedData.value.sourceType.key == undefined ? { key: selectedData.value.sourceType } : { key: selectedData.value.sourceType.key };
    data.parts = [...selectedParts.value];
    data.locationReference = props.locationReference;
    const response = await postConstructionDetails(data);

    if (!response.error) {
      proxy.$toaster.success("Gegevens van dit gebouw zijn successvol toegevoegd.");
      if (isAddingMasterMode.value) {
        navigateToLocation(props.locationReference);
      } else {
        setReadonlyMode();
        fetchData();
      }
    } else {
      proxy.$toaster.error(response.error);
    }
  }
  isSaving.value = false;
}

async function onCancelAdd() {
  if (isAddingMasterMode.value) {
    proxy.$toaster.warning(`Gebouwgegevens toevoegen geannuleerd.`);
    navigateToLocation(props.locationReference);
  } else {
    proxy.$toaster.warning(`Gebouwgegevensversie toevoegen geannuleerd.`);
    setReadonlyMode();
    resetSelectedDetail();
  }
}

async function onCancel() {
  proxy.$toaster.warning(`bewerken geannuleerd.`);
  selectNewIndex(selectedTabIndex.value);
  requestAnimationFrame(() => {
    obs.value.reset();
  });
  setReadonlyMode();
}

async function loadSourceTypes() {
  const response = await getConstructionSourceTypes();
  // console.log("Construction loadSourceTypes ", response);
  if (response && !response.error) {
    sourceTypes.value = _.sortBy([...response.data.keyDescList], "description");
    sourceTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de construction source types is mislukt! " + response.error);
  }
}

async function loadBoundaryTypes() {
  const response = await getConstructionBoundaryTypes();
  // console.log("Construction loadBoundaryTypes ", response);
  if (response && !response.error) {
    boundaryTypes.value = _.sortBy([...response.data.keyDescList], "description");

    boundaryTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de construction boundary types is mislukt! " + response.error);
  }
}

async function loadKinds() {
  const response = await getConstructionKinds();
  // console.log("Construction loadKinds ", response);
  if (response && !response.error) {
    loadedKinds.value = _.sortBy([...response.data.keyDescList], "description");

    kindsLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de construction kinds is mislukt! " + response.error);
  }
}

async function loadInsulationCategories() {
  const response = await getConstructionInsulationCategory();
  // console.log("Construction loadInsulationCategories ", response);
  if (response && !response.error) {
    insulationCategories.value = response.data.insulationCategories;
    insulationCategoriesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de constructie isolatie waarden is mislukt! " + response.error);
  }
}
</script>

<template>
  <v-container fluid pa-0>
    <v-form>
      <ValidationObserver ref="obs">
        <header class="app-welcome-message">
          <h2 v-if="!isAddingMasterMode && !isAddingDetailMode">Details van gebouwgegevens</h2>
          <h2 v-if="isAddingMasterMode">Toevoegen van gebouwgegevens</h2>
          <h2 v-if="isAddingDetailMode">Toevoegen van gebouwgegevensversie</h2>
        </header>
        <v-container fluid pa-0 class="details-button-footer-visible">
          <v-row>
            <v-col>
              <v-container fluid>
                <v-row align="center">
                  <v-col v-if="hasDetails" cols="auto"> Tijdlijn: </v-col>
                  <v-col>
                    <v-btn-toggle v-model="selectedTabIndex" group mandatory color="blue accent-3">
                      <v-btn class="v-application elevation-2" v-for="(item, index) in detailTabs()" :key="index" :value="index" :disabled="!isReadOnly"> {{ item }} </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
              </v-container>
              <masonry :cols="{ default: 2, 1264: 1 }" :gutter="12">
                <v-container fluid class="boxed" :class="{ noBorders: !isEditTLMode }">
                  <header>Geldigheid</header>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Geldig vanaf" rules="date_between|required" v-slot="{ errors }" :mode="checkDateValid">
                        <date-input
                          v-model="selectedData.valFrom"
                          persistent-placeholder
                          label="Geldig vanaf"
                          :errors="errors"
                          :class="{ noBorders: !allowEditTL }"
                          :outlined="allowEditTL"
                          :readonly="!allowEditTL"
                          rules="required"
                        ></date-input>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Geldig tot" :rules="`date_between|date_after:${selectedData.valFrom}`" v-slot="{ errors }" :mode="checkDateValid">
                        <date-input
                          v-model="selectedData.valTill"
                          persistent-placeholder
                          label="Geldig tot"
                          :errors="errors"
                          :class="{ noBorders: !allowEditTL }"
                          :outlined="allowEditTL"
                          :readonly="!allowEditTL"
                        ></date-input>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container fluid class="boxed" :class="{ noBorders: !isEditDataMode && !isEditTLMode }">
                  <header>Aanpassing</header>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Aanpassings omschrijving" rules="required" v-slot="{ errors }">
                        <v-text-field
                          hide-details="auto"
                          persistent-placeholder
                          label="Omschrijving"
                          v-model="selectedData.modDescr"
                          :error-messages="errors"
                          :class="{ noBorders: !allowEdit && !allowEditTL }"
                          :outlined="allowEdit || allowEditTL"
                          :readonly="!allowEdit && !allowEditTL"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row v-if="!isAddingMasterMode && !isAddingDetailMode">
                    <v-col>
                      <v-text-field hide-details="auto" label="Door" v-model="selectedData.modBy" readonly class="noBorders"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="!isAddingMasterMode && !isAddingDetailMode">
                    <v-col>
                      <v-text-field hide-details="auto" label="Datum" v-model="modDateFormatted" readonly class="noBorders"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container fluid class="boxed" :class="{ noBorders: !isEditTLMode }">
                  <header>Bron</header>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Brontype" rules="required" v-slot="{ errors }">
                        <v-select
                          hide-details="auto"
                          label="Type"
                          :items="sourceTypes"
                          item-text="description"
                          item-value="key"
                          v-model="selectedData.sourceType"
                          :error-messages="errors"
                          :readonly="!isAddingMasterMode"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-container>
              </masonry>

              <masonry :cols="{ default: 2, 768: 1 }" :gutter="12">
                <v-container fluid class="boxed">
                  <header>Oppervlakte</header>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Als: Verliesoppervlakte" rules="decimal:2|between:0,9999.99" v-slot="{ errors }">
                        <v-text-field
                          ref="usableLossSurface"
                          hide-details="auto"
                          type="number"
                          step="0.01"
                          label="Als: Verliesoppervlakte"
                          persistent-placeholder
                          :value="selectedData.usableLossSurface != undefined ? Math.abs(selectedData.usableLossSurface) : undefined"
                          @input="selectedData.usableLossSurface = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col>
                      <ValidationProvider name="Als/Ag: Geometrieverhouding" rules="decimal:2|between:0,100" v-slot="{ errors }">
                        <v-text-field
                          ref="usableGeometry"
                          hide-details="auto"
                          type="number"
                          step="0.01"
                          label="Als/Ag: Geometrieverhouding"
                          persistent-placeholder
                          :value="selectedData.usableGeometry != undefined ? Math.abs(selectedData.usableGeometry) : undefined"
                          @input="selectedData.usableGeometry = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-container>
              </masonry>

              <masonry :cols="{ default: 1 }" :gutter="12">
                <v-container fluid class="boxed">
                  <header>Soort</header>
                  <div v-for="(item, index) in kinds" :key="item.key">
                    <v-divider v-if="index > 0"></v-divider>
                    <ConstructionKindInput
                      :kindKey="item.key"
                      :description="item.description"
                      :boundaryTypes="boundaryTypes"
                      :loadedKinds="loadedKinds"
                      :insulationCategories="insulationCategories"
                      :editMode="allowEdit"
                      :partData="getPartData(item.key)"
                      :onlyCategories="false"
                      @update-data="updatePart"
                    ></ConstructionKindInput>
                  </div>
                </v-container>
              </masonry>
            </v-col>
          </v-row>
        </v-container>
        <v-footer fixed class="details-buttons">
          <details-buttons
            :addMode="isAddingMode"
            :editMode="!isReadOnly"
            :allowSave="!isSaving"
            @edit="setEditMode"
            @edit-tl="setEditTLMode"
            @on-cancel="onCancel"
            @add-new-detail="addNewDetail"
            @on-cancel-add="onCancelAdd()"
            @save-detail="saveDetail"
            @save-new-detail="saveNewDetail"
          />
        </v-footer>
      </ValidationObserver>
    </v-form>
  </v-container>
</template>
