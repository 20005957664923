import { apiClient, severityCode } from "./httpClient";

const END_POINT_LOC = "location";
const END_POINT_BLD = "construction";

// you can pass arguments to use as request parameters/data
// const getBuilding = async (buidlingId) => await apiClient.get(END_POINT, { buidlingId });

const getConstructionsOverview = async (reference, currentPage, pageSize, sortBy, sortDesc, filterData) => {
  let errorResponse = undefined;
  const response = await apiClient
    .post(`${END_POINT_LOC}/${reference}/constructionsoverview`, { filterData: filterData }, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } })
    .catch(error => {
      errorResponse = error.toString();
    });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getConstructions = async (currentPage, fetchDataOrigin, filterData) => {
  const response = await apiClient.post(`${END_POINT_BLD}/list`, { ...filterData }, { params: { page: currentPage, "event-origin": fetchDataOrigin } });
  return response;
};

const getConstructionFilterOptions = async (constructionId, fieldName, text) => {
  return await apiClient.get(`${END_POINT_BLD}/AutoComplete`, { params: { constructionId: constructionId, fieldname: fieldName, text: text } });
};

const getConstruction = async constructionId => {
  let errorResponse = null;
  let response = await apiClient.get(`${END_POINT_BLD}/details`, { params: { constructionId: constructionId } }).catch(error => {
    errorResponse = error.toString();
  });
  if (errorResponse) {
    response = "";
  }
  return { data: response.data, error: errorResponse };
};

const getConstructionDetails = async constructionId => {
  let errorResponse = undefined;
  let response = await apiClient.get(`${END_POINT_BLD}/${constructionId}/details`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const patchConstructionDetails = async (constructionDetailId, data) => {
  let errorResponse = undefined;
  let apiError = undefined;
  const response = await apiClient.patch(`${END_POINT_BLD}/${constructionDetailId}`, { ...data }).catch(error => {
    apiError = error;
  });

  if (apiError != undefined) {
    switch (apiError.response?.status) {
      // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
      case 409: // conflict
        errorResponse = "Er is een conflict op de tijdlijn";
        break;
      default:
        errorResponse = apiError.toString();
    }
  } else {
    switch (response.status) {
      case 204: // no-content
        errorResponse = "Er zijn geen gegevens geupdate";
        break;
    }
  }

  return { data: response ? response.data : undefined, error: errorResponse };
};

const postConstructionDetails = async data => {
  let errorResponse = undefined;
  let response = await apiClient.post(`${END_POINT_BLD}`, { ...data }).catch(error => {
    switch (error.response?.status) {
      // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
      case 409: // conflict
        errorResponse = "Er is een conflict op de tijdlijn";
        break;
      default:
        errorResponse = error.toString();
    }
  });
  if (response) {
    switch (response.status) {
      // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
      case 204: // no-content
        errorResponse = "Er zijn geen gegevens geinsert";
        break;
      default:
    }
  }
  return { data: response ? response.data : undefined, error: errorResponse };
};

export { getConstructions, getConstructionFilterOptions, getConstruction, getConstructionsOverview, getConstructionDetails, patchConstructionDetails, postConstructionDetails, severityCode };
